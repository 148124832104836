import React from 'react';
import { Box, Text, Container, Button } from 'theme-ui';
import { Link } from 'gatsby';
import logo from 'assets/dominus-logo.png';

const NotFoundPage = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        textAlign: 'center',
        bg: 'background',
        p: 4,
      }}
    >
      <Box as="img" src={logo} alt="Dominus Electricity Logo" sx={{ width: '150px', mb: 4 }} />
      <Text as="h1" sx={{ fontSize: 5, color: 'text', mb: 3 }}>
        Oops! Stranica nije pronađena
      </Text>
      <Text as="p" sx={{ fontSize: 3, color: 'textSecondary', mb: 3 }}>
        Čini se da stranica koju tražite ne postoji. Možda ste ukucali pogrešnu adresu ili je stranica uklonjena.
      </Text>
      <Text as="p" sx={{ fontSize: 3, color: 'textSecondary', mb: 4 }}>
        Ako vam trebaju profesionalne elektroinstalacije ili popravke, tu smo da vam pomognemo!
      </Text>
      <Link to="/" sx={{ textDecoration: 'none' }}>
        <Button
          sx={{
            bg: '#ffc32c',
            color: 'white',
            px: 4,
            py: 2,
            fontSize: 2,
            '&:hover': {
              bg: '#e6b800',
            },
          }}
        >
          Povratak na početnu stranicu
        </Button>
      </Link>
    </Container>
  );
};

export default NotFoundPage;
